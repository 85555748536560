import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getData } from "../services/http.service";
import Constants from "../services/constant";
import { formatDate, formatCurrency } from "../utils/dateConversions";

function Payments() {
    useEffect(() => {
        getAllUsers();
    }, []);
    const [loading, setLoading] = useState(false);

    const getAllUsers = () => {
        setLoading(true);
        getData(Constants.END_POINT.PAYMENTS)
            .then((res) => {
                setLoading(false);
                setData(res.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const [data, setData] = useState({});

    const PaymentStatus = (rowData) => {
        return <>{rowData?.status ? <span className={`product-badge status-${rowData?.status === "SUCCESS" ? "instock" : "outofstock"}`}>{rowData?.status}</span> : null}</>;
    };
    const ServiceStatus = (rowData) => {
        return <>{rowData?.bookingId?.status ? <span className={`product-badge status-${rowData?.bookingId?.status === "COMPLETED" ? "instock" : "outofstock"}`}>{rowData?.bookingId?.status}</span> : null}</>;
    };
    return (
        <>
            <div className="grid">
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Total Amount Recieved</span>
                                <div className="text-900 font-medium text-xl">{data.totalAmount && formatCurrency(data.totalAmount)}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-comment text-purple-500 text-xl" />
                            </div>
                        </div>
                        {/* <span className="text-green-500 font-medium">85 </span>
                        <span className="text-500">responded</span> */}
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Bookings</span>
                                <div className="text-900 font-medium text-xl">0</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-comment text-purple-500 text-xl" />
                            </div>
                        </div>
                        {/* <span className="text-green-500 font-medium">85 </span>
                        <span className="text-500">responded</span> */}
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Bookings</span>
                                <div className="text-900 font-medium text-xl">0</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-comment text-purple-500 text-xl" />
                            </div>
                        </div>
                        {/* <span className="text-green-500 font-medium">85 </span>
                        <span className="text-500">responded</span> */}
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Bookings</span>
                                <div className="text-900 font-medium text-xl">0</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-comment text-purple-500 text-xl" />
                            </div>
                        </div>
                        {/* <span className="text-green-500 font-medium">85 </span>
                        <span className="text-500">responded</span> */}
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card">
                    <h5>Payments</h5>
                    <DataTable value={data?.payments} paginator rows={10} loading={loading}>
                        <Column field="userId.firstName" header="Customer" sortable style={{ width: "30px" }} />
                        <Column field="businessId.businessName" header="Business" sortable style={{ width: "30px" }} />
                        <Column field="providerId.firstName" header="Provider" sortable style={{ width: "30px" }} />
                        <Column field="amount" header="Amount" bodyStyle={(d) => formatCurrency(d.amount)} sortable style={{ width: "30px" }} />
                        <Column field="bookingId.status" header="Service" sortable body={ServiceStatus} style={{ width: "30px" }} />
                        <Column field="paymentFor" header="Payment For" sortable style={{ width: "30px" }} />
                        <Column field="status" header="Status" sortable body={PaymentStatus} style={{ width: "30px" }} />
                        <Column field="time" header="Created On" sortable body={(d) => formatDate(new Date(d.time))} style={{ width: "30px" }} />
                    </DataTable>
                </div>
            </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Payments, comparisonFn);
