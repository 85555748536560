import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Link } from "react-router-dom";
import { convertTime24to12 } from "../utils";
import Constants from "../services/constant";
import { getData, postData } from "../services/http.service";

function Bookings() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [booking, setBooking] = useState();
    const [filterdate, setFilterDate] = useState({
        bookingsFrom: null,
        bookingsTo: null,
    });
    let { bookingsFrom, bookingsTo } = filterdate;
    bookingsFrom = bookingsFrom ? moment(new Date(bookingsFrom)).format("DD-MM-YYYY") : null;
    bookingsTo = bookingsTo ? moment(new Date(bookingsTo)).format("DD-MM-YYYY") : null;

    useEffect(() => {
        getCounts();
        getAllBokings();
    }, []);
    useEffect(() => {
        if (filterdate?.bookingsTo || filterdate?.bookingsFrom) {
        }
        getAllBokings1();
    }, [filterdate]);
    const getAllBokings = () => {
        setLoading(true);
        postData(Constants.END_POINT.ALL_BOOKINGS)
            .then((res) => {
                setLoading(false);
                setBooking(res.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const getAllBokings1 = () => {
        postData(Constants.END_POINT.ALL_BOOKINGS, { bookingsFrom: bookingsFrom, bookingsTo: bookingsTo })
            .then((res) => {
                setBooking(res.data);
            })
            .catch((err) => console.log(err));
    };
    const getCounts = () => {
        getData(Constants.END_POINT.GET_COUNT)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => console.log(err));
    };

    const bookingTemplate = (rowData) => {
        return (
            <Link
                to={{
                    pathname: `/bookingDetail/${rowData?._id}`,
                    state: {
                        booking: rowData?.business?.location,
                        user: rowData?.address?.location,
                    },
                }}
            >
                {rowData?.business?.businessName}
            </Link>
        );
    };
    const userBodyTemplate1 = (rowData) => {
        return rowData?.user ? rowData?.user?.firstName : rowData?.client?.firstName + "*";
    };
    const startTime = (rowData) => {
        return convertTime24to12(rowData?.startTime);
    };
    const bookingDateTemplate = (rowData) => {
        return moment(rowData?.bookingDate).format("DD-MM-YYYY");
    };
    const statusbodyTemplate = (rowData) => {
        return <span className={`product-badge status-${rowData.status === "CONFIRMED" ? "instock" : "outofstock"}`}>{rowData.status}</span>;
    };
    const userBodyTemplate = (rowData) => {
        return (
            <Link
                to={{
                    pathname: `/providerInfo/${rowData?._id}`,
                    state: {
                        booking: rowData?.business?.location,
                    },
                }}
            >
                {rowData?.provider ? rowData?.provider?.firstName : "-"}
            </Link>
        );
    };
    const handleChange = (name) => (event) => {
        let value = event.target.value;
        setFilterDate({ ...filterdate, [name]: value });
    };
    const dateTemplate = (date) => {
        return date.day;
    };
    return (
        <div>
            <div className="col-12 xl:col-12">
                <div className="card">
                    <h5 className="ml-3">Recent Bookings</h5>
                    <>
                        <div className="flex mb-3">
                            <div>
                                <label className="h6 ml-3">From Date</label>
                                <Calendar className="mx-3" placeholder="MM/DD/YYYY" value={filterdate?.bookingsFrom} onChange={handleChange("bookingsFrom")} name="bookingsFrom" dateTemplate={dateTemplate} />
                            </div>
                            <div>
                                <label className="h6 ml-3">To Date</label>
                                <Calendar className="mx-3" minDate={filterdate.bookingsFrom} placeholder="MM/DD/YYYY" value={filterdate?.bookingsTo} name="bookingsTo" onChange={handleChange("bookingsTo")} dateTemplate={dateTemplate} />
                            </div>
                        </div>

                        <DataTable value={booking} rows={10} paginator responsiveLayout="scroll" loading={loading}>
                            <Column body={bookingTemplate} header="Business Name"></Column>
                            <Column body={bookingDateTemplate} header="Booking Date"></Column>
                            <Column body={startTime} header="Start Time"></Column>
                            <Column body={statusbodyTemplate} header="Status"></Column>
                            <Column body={userBodyTemplate} header="Provider"></Column>
                            <Column body={userBodyTemplate1} header="User"></Column>
                            <Column field="duration" header="Duration"></Column>
                        </DataTable>
                    </>
                </div>
            </div>
        </div>
    );
}

export default Bookings;
