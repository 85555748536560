import React from "react";

export const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            <img src={props.layoutColorMode === "light" ? "assets/layout/images/footerLogo.png" : "assets/layout/images/footerLogo.png"} alt="Logo" height="20" className="mr-2" />

            <span className="font-medium">
                {" "}
                Task<span style={{ color: "#6366f1" }}>Buddy</span>
            </span>
        </div>
    );
};
