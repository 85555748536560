import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getData } from "../services/http.service";
import Constants from "../services/constant";
import profile from "../assets/demo/flags/profile.png";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { formatDate } from "../utils/dateConversions";

const Users = () => {
    useEffect(() => {
        getAllUsers();
    }, []);
    const [loading, setLoading] = useState(false);
    const getAllUsers = () => {
        setLoading(true);
        getData(Constants.END_POINT.USERS)
            .then((res) => {
                setLoading(false);
                if (res.success) {
                    setUsers(res.data);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };
    const blockUser = (id) => {
        getData(Constants.END_POINT.BLOCK_UNBLOCK_USER + id)
            .then((res) => {
                getAllUsers();
            })
            .catch((err) => console.log(err));
    };
    const [users, setUsers] = useState([]);

    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const imageBodyTemplate = (rowData) => {
        return <img src={rowData.image ? Constants.BASE_URL + rowData.image : profile} alt={rowData.image} width={50} />;
    };
    const statusBodyTemplate2 = (rowData) => {
        return <span className={`product-badge status-${rowData.isOnline ? "instock" : "outofstock"}`}>{rowData.isOnline ? "Online" : "Offline"}</span>;
    };
    const ActionBodyTemplate = (rowData) => {
        return (
            <div className=" flex ">
                <Link to={`/user-view/${rowData?._id}`}>
                    <Button type="button" className="p-buuton-raised p-button-rounded p-button-outlined mx-2">
                        View
                    </Button>
                </Link>
                <Button type="button" className={`p-button-raised p-button-rounded  p-button-outlined ${rowData?.isDeleted ? "p-button-success" : "p-button-danger"}`} onClick={() => blockUser(rowData?._id)}>
                    {rowData?.isDeleted ? "Unblock" : "Block"}
                </Button>
            </div>
        );
    };
    return (
        <div className="col-12">
            <div className="card">
                <h5>Users</h5>
                <div className="col-3">
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </div>
                <DataTable
                    loading={loading}
                    value={users}
                    paginator
                    rows={5}
                    filters={filters}
                    filterDisplay="row"
                    // expandedRows={expandedRows}
                    // onRowToggle={(e) => setExpandedRows(e.data)}
                    // responsiveLayout="scroll"
                    // rowExpansionTemplate={rowExpansionTemplate}
                    // dataKey="id"
                    // header={header}
                >
                    {/* <Column expander style={{ width: "3em" }} /> */}
                    <Column field="firstName" header="Name" sortable />
                    <Column field="email" header="Email" sortable />
                    <Column field="mobile" header="Mobile" sortable />
                    <Column header="Image" body={imageBodyTemplate} />
                    <Column field="time" header="Created On" body={(d) => formatDate(new Date(d.time))} style={{ width: "30px" }} />
                    {/* <Column field="isOnline" header="Status" sortable body={statusBodyTemplate2} /> */}
                    <Column field="block" header="Action" body={ActionBodyTemplate} />
                </DataTable>
            </div>
        </div>
    );
};

export default Users;
