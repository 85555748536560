import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getData } from "../services/http.service";
import Constants from "../services/constant";

import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Rating } from "primereact/rating";
import { formatDate } from "../utils/dateConversions";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const Provider = () => {
    useEffect(() => {
        getAllUsers();
    }, []);
    const [loading, setLoading] = useState(false);
    const getAllUsers = () => {
        setLoading(true);
        getData(Constants.END_POINT.PROVIDERS)
            .then((res) => {
                setLoading(false);
                setUsers(res.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };
    const blockUser = (id) => {
        getData(Constants.END_POINT.BLOCK_UNBLOCK_PROVIDER + id)
            .then((res) => {
                getAllUsers();
            })
            .catch((err) => console.log);
    };
    const [users, setUsers] = useState();
    console.log(users, "heress");

    const [globalFilterValue, setGlobalFilterValue] = useState("");
    console.log(users, "this");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        "country.name": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const statusBodyTemplate2 = (rowData) => {
        return <>{rowData?.business?.isVerified ? <span className={`product-badge status-${rowData?.business?.isVerified === "VERIFIED" ? "instock" : "outofstock"}`}>{rowData?.business?.isVerified}</span> : null}</>;
    };
    const blockBodyTemplate = (rowData) => {
        return (
            <div className="flex">
                <Link to={`/providerInfo/${rowData?._id}`}>
                    <Button type="button" className={`p-button-raised p-button-rounded  p-button-outlined p-button-info ml-1`}>
                        View
                    </Button>
                </Link>
                <Button type="button" className={`p-button-raised p-button-rounded  p-button-outlined ${rowData?.isDeleted ? "p-button-success  ml-3" : "p-button-danger ml-3"}`} onClick={() => blockUser(rowData?._id)}>
                    {rowData?.isDeleted ? "Unblock" : "Block"}
                </Button>
            </div>
        );
    };
    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData?.business?.rating || 0} readOnly cancel={false} />;
    };

    return (
        <div className="col-12">
            <div className="card">
                <h5>Providers/Business</h5>
                <div className="col-3">
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </div>
                <DataTable value={users} paginator rows={10} filters={filters} filterDisplay="row" loading={loading}>
                    <Column field="firstName" body={(d) => d.firstName + " " + d?.lastName} header="Provider Name" sortable style={{ width: "30px" }} />
                    <Column field="business.businessName" header="Business Name" sortable style={{ width: "30px" }} />
                    <Column field="email" header="Email" sortable style={{ width: "30px" }} />
                    <Column field="business.rating" style={{ width: "10px" }} header="Reviews" sortable body={ratingBodyTemplate} />
                    <Column field="business.isVerified" header="Status" sortable body={statusBodyTemplate2} style={{ width: "30px" }} />
                    <Column field="time" header="Created On" sortable body={(d) => formatDate(new Date(d.time))} style={{ width: "30px" }} />
                    <Column field="block" header="Action" body={blockBodyTemplate} style={{ width: "40px" }} />
                </DataTable>
            </div>
        </div>
    );
};

export default Provider;
