import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import Constants from "../../services/constant";

import { postData } from "../../services/http.service";
import { getData, putData, deleteData } from "../../services/http.service";

function PromationsPlans() {
    const [formerror, setformerror] = useState({});
    const [isSumbit, setisSumbit] = useState(false);
    const [loading, setLoading] = useState(false);

    const [updateData, setupdateData] = useState({
        title: "",
        price: "",
        days: "",
    });

    const [dataid, setdataid] = useState();

    const [tableData, settableData] = useState(null);

    useEffect(() => {
        datatable();
    }, []);

    // get data table function
    const datatable = () => {
        setLoading(true);
        getData(Constants.END_POINT.GET_TABLE_DATA_PROMATIONS)
            .then((res) => {
                setLoading(false);
                settableData(res.data);
            }, [])
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };
    //   get handle Edit function
    const handleEdit = (id) => {
        setopenModal2(true);

        return getData(Constants.END_POINT.GET_TABLE_DATA_PROMATIONS + id)
            .then((res) => {
                setupdateData(res.data);
            }, [])
            .catch((err) => console.log(err));
    };

    // Edit Submit Function

    const sumbitUpdate = (e) => {
        e.preventDefault();
        setformerror(validationform(updateData));
        setisSumbit(false);
        if (validationform(updateData) === "") {
            return putData(Constants.END_POINT.EDIT_TABLE_DATA_PROMATIONSEDIT + dataid, updateData)
                .then((res) => {
                    if (res.success) {
                        setupdateData("");
                        setopenModal2(false);
                        datatable();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const updateChange = (event) => {
        const { name, value } = event.target;
        setupdateData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    //  Action  Table btn  EDit and Delete functions
    const actionTemplate = (rowData) => {
        return (
            <div className="d-flex">
                <Button
                    type="button"
                    icon="pi pi-pencil"
                    className="p-button-warning  px-5 m-1"
                    onClick={() => {
                        handleEdit(rowData._id);
                        setdataid(rowData._id);
                    }}
                ></Button>
                <Button type="button" className="p-button-danger  m-1 " onClick={() => deletepopup(rowData._id, "top-right")}>
                    Delete
                </Button>
            </div>
        );
    };
    //  delete table data function
    const deleteTableData = (id) => {
        deleteData(Constants.END_POINT.GET_TABLE_DATA_PROMATIONSDELTE + id)
            .then((res) => {
                if (res.success) {
                    toast.current.show({ severity: "info", summary: "Confirmed", detail: "Safety Rules has been Deleted Succesfully", life: 3000 });
                }
                datatable();
            })
            .catch((err) => console.log(err));
    };
    const reject = () => {
        toast.current.show({ severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000 });
    };

    const toast = useRef(null);
    //  delete pop up function
    const deletepopup = (event, position) => {
        confirmDialog({
            message: "Do you want to delete this Safety Rule?",
            icon: "pi pi-info-circle",
            header: "Delete Confirmation",
            acceptClassName: "p-button-danger",
            position,
            accept: () => deleteTableData(event, position),
            reject,
        });
    };
    const [openModal2, setopenModal2] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const [AddData, setAddData] = useState({
        title: "",
        price: "",
        days: "",
    });
    //  Add data  function
    const handleChange = (event) => {
        const { name, value } = event.target;
        setAddData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (Object.keys(formerror).length === 0 && isSumbit) {
            console.log(AddData);
        }
    }, [formerror, AddData, isSumbit]);
    //  vadition  function

    const validationform = (value) => {
        const errors = {};
        if (!value.title) {
            errors.title = "This field is requried";
        }

        if (!value.price) {
            errors.price = "This field is requried";
        }
        if (!value.days) {
            errors.days = "This field is requried";
        }
        if (errors.title || errors.days || errors.price) return errors;
        else return "";
    };
    // Add data sumbit function
    const handleSumbit = (e) => {
        e.preventDefault();
        setformerror(validationform(AddData));
        setisSumbit(true);
        if (validationform(AddData) === "") {
            return postData(Constants.END_POINT.POST_ADD_PROMATIONS, AddData)
                .then((res) => {
                    if (res.success) {
                        setopenModal(false);
                        datatable();
                        setAddData("");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    return (
        <>
            <Toast ref={toast} />
            <div className="grid table-demo">
                <div className="col-12">
                    <div className="flex justify-content-between mb-4">
                        <h5>Promotions Plans</h5>
                        <Button icon="pi pi-plus" label="Promotions" onClick={() => setopenModal(true)} />
                    </div>
                    <DataTable value={tableData} responsiveLayout="scroll" paginator rows={8} loading={loading}>
                        <Column field="title" header="Title" style={{ width: "40%" }}></Column>
                        <Column field="price" header="Price" style={{ width: "25%" }}></Column>
                        <Column field="days" header="Days" style={{ width: "25%" }}></Column>
                        <Column header="Action" body={actionTemplate} style={{ width: "10%" }}></Column>
                    </DataTable>
                    <Dialog header=" Promitions" visible={openModal} maximizable style={{ width: "40vw" }} onHide={() => setopenModal(false)}>
                        <div>
                            <h6>Add Promotions</h6>
                            <label htmlFor="username">Title</label>
                            <InputText type="text" style={{ width: "100%" }} l name="title" value={AddData.title} onChange={handleChange} />
                            <span className="text-danger d-block my-1">{formerror.title}</span>

                            <label htmlFor="username">Price</label>
                            <InputText type="number" style={{ width: "100%" }} name="price" value={AddData.price} onChange={handleChange} />
                            <span className="text-danger d-block my-1">{formerror.price}</span>
                            <label htmlFor="username">Days</label>
                            <InputText type="number" style={{ width: "100%" }} name="days" value={AddData.days} onChange={handleChange} />
                            <span className="text-danger d-block my-1">{formerror.days}</span>
                            <div className="d-flex justify-content-end mt-4">
                                <Button type="submit" icon="pi pi-check" label="Add" onClick={handleSumbit} />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header=" Promitions" visible={openModal2} maximizable style={{ width: "40vw" }} onHide={() => setopenModal2(false)}>
                        <div>
                            <h6>Add Promotions</h6>
                            <label htmlFor="username">Title</label>
                            <InputText type="text" style={{ width: "100%" }} l name="title" value={updateData.title} onChange={updateChange} />
                            <span className="text-danger d-block my-1">{formerror.title}</span>

                            <label htmlFor="username">Price</label>
                            <InputText type="number" style={{ width: "100%" }} name="price" value={updateData.price} onChange={updateChange} />
                            <span className="text-danger d-block my-1">{formerror.price}</span>
                            <label htmlFor="username">Days</label>
                            <InputText type="number" style={{ width: "100%" }} name="days" value={updateData.days} onChange={updateChange} />
                            <span className="text-danger d-block my-1">{formerror.days}</span>
                            <div className="d-flex justify-content-end mt-4">
                                <Button type="submit" icon="pi pi-check" label="Save" onClick={sumbitUpdate} />
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    );
}

export default PromationsPlans;
